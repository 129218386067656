body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root{
  height: 100vh;
  background-color: rgb(38, 38, 39);
}

.theme-light {
  --main: #137586;
  --main-hover: #146a79;
  --nav-text:white;
  --main-text: black;
  --background:#C7E8E8;
  --background-elevate: #d3f0f0;
  --accent:#052460;
  --accent-hover:#093690;
  --accent-text: white;
  --bg-text: white;
  --img-brightness: 100%;
}

.theme-dark {
  --main: #09348a;
  --main-hover: #0d49c2;
  --main-text: white;
  --nav-text:white;
  --background:#031A3F;
  --background-elevate:#0d254b;
  --accent:#F3DE8A;
  --accent-hover: #c2b16f;
  --accent-text: black;
  --bg-text: white;
  --img-brightness: 90%;
}

.nav-bar{
  background-color: var(--main);
}

.main{
  background-color: var(--background);
  color: var(--main-text);
  display: flex;
  flex-direction: row;
  height: 93%;
}

#lm{
  height: auto;
  min-height: 93%;
}

.accent{
  background-color: var(--accent);
  color: var(--accent-text);
}

.react-link{
  text-decoration: none !important;
  color: white !important;
  padding:15%;
}

.react-link:hover{
  text-decoration: underline !important;
  background-color: var(--main-hover);
  height: 100%;

}

.button-theme{
  background-color: var(--main) !important;
  padding: 1.5em;
  border-radius: 2em;
  margin: 6px;
  font-size: 12.96px;
}

.button-theme-active{
  background-color: var(--accent) !important;
  color: var(--accent-text) !important;
  padding: 1.5em;
  border-radius: 2em;
  margin: 6px;
  font-size: 12.96px;
}

:root {
  /* NEW Scaling Variables */
  --phin-s-2: calc(var(--phin-s0) * .5); /* 8px */
  --phin-s-1: calc(var(--phin-s0) * .75); /* 12px */
  --phin-s0: 1rem; /* 16px */
  --phin-s1: calc(var(--phin-s0) * 1.5); /* 24px */
  --phin-s2: calc(var(--phin-s0) * 2); /* 32px */
  --phin-s3: calc(var(--phin-s0) * 3); /* 48px */
  --phin-s4: calc(var(--phin-s0) * 4); /* 64px */
  --phin-s5: calc(var(--phin-s0) * 6); /* 96px */
  --phin-s6: calc(var(--phin-s0) * 8); /* 128px */
  --phin-s7: calc(var(--phin-s0) * 12); /* 192px */
  --phin-s8: calc(var(--phin-s0) * 16); /* 256px */
  --phin-s9: calc(var(--phin-s0) * 24); /* 384px */
  --phin-s10: calc(var(--phin-s0) * 32); /* 512px */

  /* Standard Color Variables */
  --phin-white: #fff;
} 

.padding\:-2 { padding: var(--phin-s-2) }
.padding\:-1 { padding: var(--phin-s-1) } 
.padding\:0 { padding: var(--phin-s0) }
.padding\:1 { padding: var(--phin-s1) }
.padding\:2 { padding: var(--phin-s2) }
.padding\:3 { padding: var(--phin-s3) }
.padding\:4 { padding: var(--phin-s4) }
.padding\:5 { padding: var(--phin-s5) }
.padding\:6 { padding: var(--phin-s6) }

.margin\:-2 { margin: var(--phin-s-2) }
.margin\:-1 { margin: var(--phin-s-1) } 
.margin\:0 { margin: var(--phin-s0) }
.margin\:1 { margin: var(--phin-s1) }
.margin\:2 { margin: var(--phin-s2) }
.margin\:3 { margin: var(--phin-s3) }
.margin\:4 { margin: var(--phin-s4) }
.margin\:5 { margin: var(--phin-s5) }
.margin\:6 { margin: var(--phin-s6) }


/* PHIN Margin + Padding overrides */
.margin\:none{
  margin: 0
}
.margin-bottom\:-2{
  margin-bottom: var(--phin-s-2);
}
.margin-bottom\:-1{
  margin-bottom: var(--phin-s-1);
}
.margin-bottom\:0{
  margin-bottom: var(--phin-s0);
}
.margin-bottom\:1{
  margin-bottom: var(--phin-s1);
}
.margin-bottom\:2{
  margin-bottom: var(--phin-s2);
}
.margin-bottom\:3{
  margin-bottom: var(--phin-s3);
}
.margin-bottom\:4{
  margin-bottom: var(--phin-s4);
}
.margin-bottom\:5{
  margin-bottom: var(--phin-s5);
}
.margin-bottom\:6{
  margin-bottom: var(--phin-s6);
}
.margin-top\:-2 {
  margin-top: var(--phin-s-2);
}
.margin-top\:-1 {
  margin-top: var(--phin-s-1);
}
.margin-top\:0 {
  margin-top: var(--phin-s0);
}
.margin-top\:1 {
  margin-top: var(--phin-s1);
}
.margin-top\:2 {
  margin-top: var(--phin-s2);
}
.margin-top\:3 {
  margin-top: var(--phin-s3);
}
.margin-top\:4 {
  margin-top: var(--phin-s4);
}
.margin-top\:5 {
  margin-top: var(--phin-s5);
}
.margin-top\:6 {
  margin-top: var(--phin-s6);
}
.margin-left\:-2 {
  margin-left: var(--phin-s-2);
}
.margin-left\:-1 {
  margin-left: var(--phin-s-1);
}
.margin-left\:0 {
  margin-left: var(--phin-s0);
}
.margin-left\:1 {
  margin-left: var(--phin-s1);
}
.margin-left\:2 {
  margin-left: var(--phin-s2);
}
.margin-left\:3 {
  margin-left: var(--phin-s3);
}
.margin-left\:4 {
  margin-left: var(--phin-s4);
}
.margin-left\:5 {
  margin-left: var(--phin-s5);
}
.margin-left\:6 {
  margin-left: var(--phin-s6);
}
.margin-right\:-2 {
  margin-right: var(--phin-s-2);
}
.margin-right\:-1 {
  margin-right: var(--phin-s-1);
}
.margin-right\:0 {
  margin-right: var(--phin-s0);
}
.margin-right\:1 {
  margin-right: var(--phin-s1);
}
.margin-right\:2 {
  margin-right: var(--phin-s2);
}
.margin-right\:3 {
  margin-right: var(--phin-s3);
}
.margin-right\:4 {
  margin-right: var(--phin-s4);
}
.margin-right\:5 {
  margin-right: var(--phin-s5);
}
.margin-right\:6 {
  margin-right: var(--phin-s6);
}
.padding-right\:-2 {
  padding-right: var(--phin-s-2);
}
.padding-right\:-1 {
  padding-right: var(--phin-s-1);
}
.padding-right\:0 {
  padding-right: var(--phin-s0);
}
.padding-right\:1 {
  padding-right: var(--phin-s1);
}
.padding-right\:2 {
  padding-right: var(--phin-s2);
}
.padding-right\:3 {
  padding-right: var(--phin-s3);
}
.padding-right\:4 {
  padding-right: var(--phin-s4);
}
.padding-right\:5 {
  padding-right: var(--phin-s5);
}
.padding-right\:6 {
  padding-right: var(--phin-s6);
}
.padding-top\:-2 {
  padding-top: var(--phin-s-2);
}
.padding-top\:-1 {
  padding-top: var(--phin-s-1);
}
.padding-top\:0 {
  padding-top: var(--phin-s0);
}
.padding-top\:1 {
  padding-top: var(--phin-s1);
}
.padding-top\:2 {
  padding-top: var(--phin-s2);
}
.padding-top\:3 {
  padding-top: var(--phin-s3);
}
.padding-top\:4 {
  padding-top: var(--phin-s4);
}
.padding-top\:5 {
  padding-top: var(--phin-s5);
}
.padding-top\:6 {
  padding-top: var(--phin-s6);
}
.padding-left\:-2 {
  padding-left: var(--phin-s-2);
}
.padding-left\:-1 {
  padding-left: var(--phin-s-1);
}
.padding-left\:0 {
  padding-left: var(--phin-s0);
}
.padding-left\:1 {
  padding-left: var(--phin-s1);
}
.padding-left\:2 {
  padding-left: var(--phin-s2);
}
.padding-left\:3 {
  padding-left: var(--phin-s3);
}
.padding-left\:4 {
  padding-left: var(--phin-s4);
}
.padding-left\:5 {
  padding-left: var(--phin-s5);
}
.padding-left\:6 {
  padding-left: var(--phin-s6);
}
.padding-bottom\:-2 {
  padding-bottom: var(--phin-s-2);
}
.padding-bottom\:-1 {
  padding-bottom: var(--phin-s-1);
}
.padding-bottom\:0 {
  padding-bottom: var(--phin-s0);
}
.padding-bottom\:1 {
  padding-bottom: var(--phin-s1);
}
.padding-bottom\:2 {
  padding-bottom: var(--phin-s2);
}
.padding-bottom\:3 {
  padding-bottom: var(--phin-s3);
}
.padding-bottom\:4 {
  padding-bottom: var(--phin-s4);
}
.padding-bottom\:5 {
  padding-bottom: var(--phin-s5);
}
.padding-bottom\:6 {
  padding-bottom: var(--phin-s6);
}

h1 {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 2.488rem;
  margin-bottom: 0;
  line-height: 1.2;
}

h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 2.074rem;
  margin-bottom: 0;
  line-height: 1.2;
}

h3 {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 1.728rem;
  margin-bottom: 0;
  line-height: 1.3;
}

h4 {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 1.44rem;
  margin-bottom: 0;
  line-height: 1.3;
}

h5 {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 0;
  line-height: 1.5;
}

h6 {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0;
  line-height: 1.5;
}