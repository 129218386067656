.my-growth-container {
    height: 100%;
    display: flex; 
    justify-content: center;
}

.stats-container {
    padding-top: 4rem;
    width: 350px;
}

.title-container {
    display: flex;
    align-items: center;
}

.sub-heading-container {
    padding-top: 1.5rem;
    display: flex;
    align-items: baseline
}

.breakdown-divider {
    padding-top: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
}

.progress-row {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.topic-name {
    padding-bottom: .5rem;
    padding-right: .5rem;
    width: 90%;
    font-weight: 500;
}

.progress-cube {
    height: 20px; 
    width: 20px;
    border-radius: 5px;
    margin-right: .5rem;
    margin-bottom: .5rem
}

.my-growth-container .bold {
    font-weight: 500
}

.my-growth-container p {
    padding-left: .2rem;
}