/* themes NOTE: this is not done this exploring this */
.container-padding-xsmall {
    padding: .25em
}

.container-padding-small {
    padding: 1em;
}

.container-padding-smallish {
    padding: 2em;
}

.container-padding-large {
    padding: 5em;
}

/* more standardized lms padding classes */
.lms-plr-1 {
    padding-left: 1em;
    padding-right: 1em;
}

.lms-plr-2 {
    padding-left: 2em;
    padding-right: 2em;
}

.lms-plr-3 {
    padding-left: 3em;
    padding-right: 3em;
}

.lms-mlr-2 {
    margin-right: 2em;
    margin-left: 2em;
}

.lms-mlr-3 {
    margin-right: 3em;
    margin-left: 3em;
}

.lms-mlr-4 {
    margin-right: 4em;
    margin-left: 4em;
}

.lms-mlr-5 {
    margin-right: 5em;
    margin-left: 5em;
}

.lms-mlr-1 {
    margin-right: 1em;
    margin-left: 1em;
}

.lms-pt-1 {
    padding-top: 1em;
}

.lms-pt-2 {
    padding-top: 2em;
}

.lms-pt-3 {
    padding-top: 3em;
}

.lms-pt-6 {
    padding-top: 6em;
}

.lms-mt-2 {
    margin-top: 2em;
}

.lms-mt-3 {
    margin-top: 3em;
}

.lms-mt-4 {
    margin-top: 4em;
}

.lms-mt-6 {
    margin-top: 6em;
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

/* one off single component styling */

.nav-bar {
    height: 7%;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, .3);
    z-index: 10;
    position: relative;
    align-items: center;
}

.nav-view-btn {
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
}

.tool-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* position: static; */
}

.tool-bar>button {
    padding: .3em 2em;
    margin: 0;
    background-color: var(--main) !important;
    border-radius: 0em 0 2em 2em;
}

.details-img {
    max-width: 100%;
    max-height: 300px;
}

.details-window {
    display: box;
    position: absolute;
    width: 30%;
    height: 93%;
    z-index: 1;
}

.feed-filter {
    background-color: var(--background-elevate);
    padding: 2em 1em;
    border-radius: 1em;
}

.feed-filter>a {
    padding-top: .5em;
    text-decoration: underline !important;
    cursor: pointer;

}

.pseudo-card {
    background-color: var(--background-elevate);
    border-radius: 2em 2em 2em 2em;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, .3);
    margin-top: 3em;
    max-width: 850px;
}

.pseudo-card img {
    width: 100%;
    border-radius: 2em 2em 2em 0;
    filter: brightness(var(--img-brightness));
}


.card-details>span {
    padding: .25em 1em;
}


.dark-icon {
    color: black;
}

.theme-icon {
    color: var(--nav-text);
    padding: 0px 5px;
}


/* stolen from W3 school */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 40px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 6px;
    left: 0;
    right: 0px;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: rgb(32, 32, 32);
}

input:focus+.slider {
    box-shadow: 0 0 1px #000000;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* end of stolen slider*/

.logo {
    height: 3em;
    max-width: 100%;
    margin: 2em;
}

.feed {
    padding-top: 3em;
    overflow-y: scroll;
    width: 100%;
}

.file-back {
    background-color: white;
    filter: brightness(var(--img-brightness));
    width: 100%;
    border-radius: .22em .22em .22em 0;
}

.detail-file-back {
    background-color: white;
    filter: brightness(var(--img-brightness));
    width: 100%;
}

.description {
    overflow-wrap: break-word;
    hyphens: manual;
}

.cardContainer {
    padding: 2em;
    display: flex;
    flex-direction: row;
}

.font-a {
    font-family: 'Alegreya', serif;
    font-weight: 600;
}

.nav-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--nav-text);
}

.status-icon {
    /* justify-self: end; */
    font-size: 2em;
    align-self: end;
}

.card-title {
    width: 100%;
}

.card-title>h4 {
    margin-right: -2em !important;
    /* padding: .25em; */
    /* min-width: 100px; */
    overflow: hidden;
    white-space: nowrap;
    max-height: 1.25em;
    max-width: 60%;
    text-overflow: ellipsis;
}

.mobile-badge {
    display: none;
}

.scorm-container {
    padding: 2em;
    height: 100%;
}

.single-vid {
    object-fit: cover;
    height: 16em;
}

/* .single-vid:hover{
    object-fit: contain;
    transition: object-fit 2s linear;
} */

.acknowledge {
    position: absolute;
    bottom: 1.5em;
    right: 0em;
    background-color: var(--background);
    padding: 2em;
    border-radius: 2em 0 0 0;

}

.ack-mdb-padding-fix {
    padding-top: .125em;
    padding-right: 1em;
}

.ack-btn {
    padding: 1em 3.5em 1em 2em;
    background-color: var(--main);
    color: var(--nav-text);
    border-radius: 1em;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, .3);
}

.ack-btn-disabled {
    padding: 1em 3.5em 1em 2em;
    background-color: var(--background-elevate);
    color: var(--nav-text);
    border-radius: 1em;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, .3);
    cursor: unset !important;
}

.ack-btn:hover {
    cursor: pointer;
    background-color: var(--main-hover);
}

/* extra cursor change for fake button */

.ack-label {
    font-weight: 500;
    font-size: 1.1em;
}

.ack-label-disabled {
    font-weight: 500;
    font-size: 1.1em;
    cursor: unset !important;
}

.notification-round {
    border-radius: 2em !important;
}

.email-receipt {
    background-color: white;
    color: black;
    /* margin:0 2em; */
    border: .05em solid;
    max-width: 100%;
}

.email-info-left {
    width: 30%;
}

.email-info-right {
    padding-left: 2em;
    width: 70%;
}

.email-info-right>span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 80%;
}

.email-blackdrop {
    background-color: white;
    padding: 1em;
    color: black;
}

.lm-video-player {
    width: 100%;
    /* height: 100% !important; */
}

.lm-content-area {
    height: 100%;
}

.spinner {
    color: var(--accent);
    width: 4rem;
    height: 4rem;
}

/* quick attempt at hover over tooltip */
.hover-detail:hover>.hover-popup {
    position: relative;
    z-index: 1;
    left: 1em;
    background-color: rgba(0, 0, 0, .3);
    padding: 10px;
    color: white;
    visibility: visible;
    opacity: 1;
    transition: opacity .2s linear;
    /* border-radius: 2em; */
    border-radius: -20px 2em 2em 0;
}

.hover-popup {
    visibility: hidden;
    opacity: 0;
}

#triangle-bottomright {
    width: 0;
    height: 0;
    border-bottom: 100px solid red;
    border-left: 100px solid transparent;
}

/* end of very basic tooltip styling*/


.warning-card {
    background-color: rgb(243, 150, 150);
    color: black;
    padding: 4em;
    width: 50%;
    border: 2px solid rgb(182, 10, 10);
    border-radius: 2em;

    display: flex;
    flex-direction: row;
    justify-content: center;
}

.warning-card-title {
    font-weight: 500;
    color: rgb(182, 10, 10);
    padding-bottom: 32px;
}

.warning-card-text {
    font-weight: 400;
    padding-bottom: 32px;
}

.warning-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%
}

/* start of the media queries */
@media screen and (max-width: 1250px) {}

@media screen and (max-width: 800px) {
    /* styles apply below 800px*/

    .scorm-container {
        width: 100%;
        height: 100%;
        padding: 0;
    }

    .details-window {
        display: box;
        position: absolute;
        width: 100%;
        height: 93%;
        z-index: 1;
        overflow-y: scroll;
    }

    .nav-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .user-collapse {
        display: none;
    }

    .mobile-badge {
        display: unset;
        position: relative;
        bottom: 1em;
    }

    .status-icon {
        font-size: 1.33em;
        align-self: end;
    }


    .lm-small-view {
        margin: 2em 0 0 0;
        padding: 0;
    }

    .pseudo-card {
        background-color: var(--background-elevate);
        border-radius: 2em 2em 2em 2em;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, .3);
        margin-top: 3em;
        max-width: 350px;
    }

}


@media (orientation: landscape) and (max-width: 850px) {
    .nav-bar {
        height: 4em;
    }

    .nav-container {
        margin-top: 4.3em;
        padding-right: 0;
    }

    .details-window {
        display: box;
        position: absolute;
        width: 100%;
        height: 83%;
        z-index: 1;
        overflow-y: scroll;
    }

    .main {
        height: 83%;
    }

    .scorm-container {
        width: 100%;
        height: 100%;
        padding: 0;
    }

}

@media screen and (max-width: 450px) {
    #lm {
        display: block;
    }

    .logo {
        margin: .5rem;
    }

    .user-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var(--nav-text);
    }
}